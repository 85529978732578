
import { defineComponent, onMounted } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsTabs from '@web/components/AsTabsNew.vue'
import AsVideoConsultationsCards from '@web/components/AsVideoConsultationsCards.vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import AsBannersCatalog from '@web/components/AsBannersCatalog.vue'

import useVideoConsultations from '@web/composition/useVideoConsultations'
import useErrors from '@web/composition/useErrors'
import { useVideoconsultationsBanners } from '@web/composition/useBanners'

import sentry from '@web/common/sentry'
import { performanceEnd } from '@web/common/Utils'
import Logger from '@web/common/Logger'
import FirebasePerformance from '@web/services/firebasePerformance'
import { useMeta } from '@web/common/vueMeta'
import AsContainer from '@web/components/AsContainer.vue'

export default defineComponent({
  components: {
    AsContainer,
    MqResponsive,
    AsTabs,
    AsVideoConsultationsCards,
    AsPreloader,
    AsBannersCatalog
  },
  emits: ['loaded'],
  setup (props, ctx) {
    const {
      loaded,
      title,
      mobileTabs,
      currentTab,
      setCurrentTab,
      archiveConsultations,
      availableConsultations,
      fetchData,
      showErrorAlert
    } = useVideoConsultations()

    useMeta({
      title
    })

    const {
      serverErrors
    } = useErrors()

    async function init () {
      let videoConsultationsPageLoading = performance.now()
      const startLoading = Date.now()
      try {
        loaded.value = false
        ctx.emit('loaded', false)
        await fetchData()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        sentry.captureException(e)
        serverErrors.value = e.errors || []
        showErrorAlert()
        Logger.error(e)
      } finally {
        videoConsultationsPageLoading = performanceEnd(videoConsultationsPageLoading)
        Logger.info(`Video Consultations loaded: ${videoConsultationsPageLoading} ms`)
        loaded.value = true
        ctx.emit('loaded', true)

        FirebasePerformance.record({
          traceName: 'load_videoconsultations',
          startTime: startLoading
        })
      }
    }

    onMounted(async () => {
      init()
    })

    return {
      loaded,
      title,
      mobileTabs,
      currentTab,
      setCurrentTab,
      archiveConsultations,
      availableConsultations,
      fetchData,
      showErrorAlert,

      ...useVideoconsultationsBanners()
    }
  }
})
