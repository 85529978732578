import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3547d54e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  role: "tablist",
  class: "asTabsNew-bar",
  "data-booted": "true"
}
const _hoisted_2 = {
  key: 0,
  class: "asTabsNew-bar_title"
}
const _hoisted_3 = { class: "asTabsNew-bar_content" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "asTabsNew_tab_title" }
const _hoisted_6 = { class: "asTabsNew_tab_note" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["asTabsNew", {
      '-grow': _ctx.grow
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, key) => {
          return (_openBlock(), _createElementBlock("div", {
            key: key,
            tabindex: "0",
            "aria-selected": "true",
            role: "tab",
            class: _normalizeClass(["asTabsNew_tab", {
              '-active': key === _ctx.current
            }]),
            onClick: ($event: any) => (_ctx.selectTab(key))
          }, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(tab.name), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(tab.note), 1)
          ], 10, _hoisted_4))
        }), 128))
      ])
    ])
  ], 2))
}