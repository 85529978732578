import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_video_consultations = _resolveComponent("as-video-consultations")!
  const _component_as_banners_top = _resolveComponent("as-banners-top")!
  const _component_as_banner = _resolveComponent("as-banner")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    class: "videoConsultationsPage",
    title: _ctx.title,
    prevPage: _ctx.prevPage
  }, {
    sm: _withCtx(() => [
      _createVNode(_component_as_video_consultations, {
        onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLoaded = $event))
      })
    ]),
    md: _withCtx(() => [
      _createVNode(_component_as_video_consultations, {
        onLoaded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isLoaded = $event))
      })
    ]),
    lg: _withCtx(() => [
      _createVNode(_component_as_video_consultations, {
        onLoaded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isLoaded = $event))
      })
    ]),
    desktopTopBanners: _withCtx(() => [
      _createVNode(_component_as_banners_top, {
        platform: _ctx.bannersPlatforms.desktop,
        placement: _ctx.bannersPlacements.top,
        position: _ctx.bannersPosition,
        banners: _ctx.bannersTop
      }, null, 8, ["platform", "placement", "position", "banners"])
    ]),
    desktopSidebarBanners: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersSidebar, (banner, i) => {
        return (_openBlock(), _createBlock(_component_as_banner, {
          key: `bannerSidebar${i}`,
          banner: banner,
          platform: _ctx.bannersPlatforms.desktop,
          position: _ctx.bannersPosition,
          placement: _ctx.bannersPlacements.sidebar
        }, null, 8, ["banner", "platform", "position", "placement"]))
      }), 128))
    ]),
    desktopBottomBanners: _withCtx(() => [
      (_ctx.isLoaded)
        ? (_openBlock(), _createBlock(_component_as_banners_top, {
            key: 0,
            platform: _ctx.bannersPlatforms.desktop,
            placement: _ctx.bannersPlacements.bottom,
            position: _ctx.bannersPosition,
            banners: _ctx.bannersBottom
          }, null, 8, ["platform", "placement", "position", "banners"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "prevPage"]))
}