
import { defineComponent } from 'vue'

import AsVideoConsultationsCard from '@web/components/AsVideoConsultationsCard.vue'

export default defineComponent({
  props: {
    videoConsultations: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'available' // available || history
    }
  },
  components: {
    AsVideoConsultationsCard
  }
})
