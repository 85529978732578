import { ref, computed, Ref } from 'vue'
import Swal from 'sweetalert2'
import Time from '@web/common/Time'
import VideoConsultationStatusConst from '@web/consts/VideoConsultationStatus'
import { AvailableVideoConsultation, PurchasedVideoConsultation } from '@web/store/types/modules/videoConsultation'
import { useStore } from '@web/store'
import i18n from '@web/plugins/i18n'

interface Form {
  id: number | null;
  phone: string;
  date: string;
  time: string;
  note: string;
}

interface UseVideoConsultations {
  loaded: Ref<boolean>;
  title: Readonly<Ref<string>>;
  form: Ref<Form>;
  availableConsultations: Readonly<Ref<readonly (AvailableVideoConsultation & { purchased: PurchasedVideoConsultation[] })[]>>;
  purchasedConsultations: Readonly<Ref<readonly PurchasedVideoConsultation[]>>;
  // archiveConsultations: Readonly<Ref<readonly unknown[]>>;
  archiveConsultations: Readonly<Ref<readonly { title: string; purchased: PurchasedVideoConsultation[] }[]>>;
  purchasedVideoConsultations: Readonly<Ref<readonly PurchasedVideoConsultation[]>>;
  availableVideoConsultations: Readonly<Ref<readonly AvailableVideoConsultation[]>>;
  filteredConsultations: Readonly<Ref<readonly (AvailableVideoConsultation & { purchased: PurchasedVideoConsultation[] })[] | readonly ({ title: string; purchased: PurchasedVideoConsultation[] })[]>>;
  emptyText: Readonly<Ref<string>>;
  fetchPurchasedVideoConsultations: () => Promise<void>;
  fetchAvailableVideoConsultationsCached: () => Promise<void> | void;
  updateVideoConsultations: (payload: { id: number; time: number | string; note: string; phone: string}) => Promise<void>;
  mobileTabs: Readonly<Ref<Dictionary<{ name: string; note: string }>>>;
  currentTab: Ref<string>;
  setCurrentTab: (id: string) => void;
  fetchData: () => void;
  showErrorAlert: () => void;
  isCreated: (status: string) => boolean;
  isPlanned: (status: string) => boolean;
  isApproved: (status: string) => boolean;
  isDone: (status: string) => boolean;
  isCanceled: (status: string) => boolean;
  isRefund: (status: string) => boolean;
  isCreatedOrCanceled: (status: string) => boolean;
  isDoneOrRefund: (status: string) => boolean;
  getDate: (startDateTime: string) => string;
  getTime: (startDateTime: string) => string;
}

export default function (): UseVideoConsultations {
  const store = useStore()
  const loaded = ref(false)
  const currentTab = ref('available')
  const form = ref<Form>({
    id: null,
    phone: '',
    date: '',
    time: '',
    note: ''
  })
  const title = computed(() => String(i18n.global.t('videoConsultations.VideoConsultations')))
  const purchasedVideoConsultations = computed(() => store.getters['videoConsultation/purchasedVideoConsultations'])
  const availableVideoConsultations = computed(() => store.getters['videoConsultation/availableVideoConsultations'])

  /*
* @param Status<String> - статус видеоконференции
* @return res<Boolean> - возвращает true если конференция создана
* */
  function isCreated (status) {
    return VideoConsultationStatusConst.CREATED === status
  }

  /*
  * @param Status<String> - статус видеоконференции
  * @return res<Boolean> - возвращает true если конференция запланирована
  * */
  function isPlanned (status) {
    return VideoConsultationStatusConst.PLANNED === status
  }

  /*
  * @param Status<String> - статус видеоконференции
  * @return res<Boolean> - возвращает true если конференция потверждена
  * */
  function isApproved (status) {
    return VideoConsultationStatusConst.APPROVED === status
  }

  /*
  * @param Status<String> - статус видеоконференции
  * @return res<Boolean> - возвращает true если конференция завершена
  * */
  function isDone (status) {
    return VideoConsultationStatusConst.DONE === status
  }

  /*
  * @param Status<String> - статус видеоконференции
  * @return res<Boolean> - возвращает true если конференция отменена
  * */
  function isCanceled (status) {
    return VideoConsultationStatusConst.CANCELED === status
  }

  /*
  * @param Status<String> - статус видеоконференции
  * @return res<Boolean> - возвращает true если был Возврат денег
  * */
  function isRefund (status) {
    return VideoConsultationStatusConst.REFUND === status
  }

  function isCreatedOrCanceled (status) {
    return isCreated(status) || isCanceled(status)
  }

  function isDoneOrRefund (status) {
    return isDone(status) || isRefund(status)
  }

  const fetchPurchasedVideoConsultations = () => store.dispatch('videoConsultation/fetchPurchasedVideoConsultations')
  const fetchAvailableVideoConsultationsCached = () => store.dispatch('videoConsultation/fetchAvailableVideoConsultationsCached')
  const updateVideoConsultations = (payload) => store.dispatch('videoConsultation/updateVideoConsultations', payload)

  const readyConsultations = computed(() => {
    const _consultations: PurchasedVideoConsultation[] = []
    purchasedVideoConsultations.value
      .forEach(videoConsultation => {
        let title = videoConsultation.title
        if (videoConsultation.course) {
          title = videoConsultation.course.title
        }
        _consultations.push({
          title,
          ...videoConsultation
        })
      })
    return _consultations
  })

  const purchasedConsultations = computed(() => {
    return readyConsultations.value.filter(videoConsultation => !isDoneOrRefund(videoConsultation.status))
  })

  const availableConsultations = computed(() => {
    return availableVideoConsultations.value.map(c => {
      return {
        ...c,
        purchased: purchasedConsultations.value.filter(videoConsultation => c.id === videoConsultation.courseId)
      }
    })
  })

  const archiveConsultations = computed<{ title: string; purchased: PurchasedVideoConsultation[] }[]>(() => {
    const _consultations = {}
    readyConsultations.value
      .filter(videoConsultation => isDone(videoConsultation.status))
      .forEach(c => {
        if (!_consultations[c.courseId]) {
          _consultations[c.courseId] = {
            title: '',
            purchased: []
          }
        }
        _consultations[c.courseId].title = c.title
        _consultations[c.courseId].purchased.push(c)
      })
    // return readyConsultations.value.filter(videoConsultation => isDone(videoConsultation.status))
    return Object.values(_consultations)
  })

  const filteredConsultations = computed(() => currentTab.value === 'available' ? availableConsultations.value : archiveConsultations.value)
  const emptyText = computed(() => currentTab.value === 'available' ? String(i18n.global.t('videoConsultations.emptyAvailable')) : String(i18n.global.t('videoConsultations.emptyHistory')))

  const mobileTabs = computed(() => {
    return {
      available: {
        name: String(i18n.global.t('videoConsultations.available')),
        note: availableConsultations.value.length ? `(${availableConsultations.value.length})` : ''
      },
      history: {
        name: String(i18n.global.t('videoConsultations.history')),
        note: archiveConsultations.value.length ? `(${archiveConsultations.value.length})` : ''
      }
    }
  })

  function setCurrentTab (id) {
    currentTab.value = id
  }

  async function fetchData () {
    await fetchAvailableVideoConsultationsCached()
    await fetchPurchasedVideoConsultations()
  }

  function showErrorAlert () {
    Swal.fire({
      icon: 'error',
      title: String(i18n.global.t('error.error')),
      text: String(i18n.global.t('error.courses'))
    })
  }

  function getDate (startDateTime) {
    return Time(startDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')
  }

  function getTime (startDateTime) {
    return Time(startDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
  }

  return {
    loaded,
    title,
    form,
    availableConsultations,
    purchasedConsultations,
    archiveConsultations,
    purchasedVideoConsultations,
    availableVideoConsultations,
    filteredConsultations,
    emptyText,

    fetchPurchasedVideoConsultations,
    fetchAvailableVideoConsultationsCached,
    updateVideoConsultations,
    mobileTabs,
    currentTab,
    setCurrentTab,
    fetchData,
    showErrorAlert,
    isCreated,
    isPlanned,
    isApproved,
    isDone,
    isCanceled,
    isRefund,
    isCreatedOrCanceled,
    isDoneOrRefund,
    getDate,
    getTime
  }
}
