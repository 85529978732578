
import { ref, defineComponent } from 'vue'

import Page from '@web/views/Page.vue'
import AsVideoConsultations from '@web/components/AsVideoConsultations.vue'
import AsBannersTop from '@web/components/AsBannersTop.vue'
import AsBanner from '@web/components/AsBanner.vue'

import useVideoConsultations from '@web/composition/useVideoConsultations'
import { useMeta } from '@web/common/vueMeta'
import { useVideoconsultationsBanners } from '@web/composition/useBanners'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  components: {
    Page,
    AsVideoConsultations,
    AsBannersTop,
    AsBanner
  },
  setup () {
    const prevPage = { name: PAGE_NAME.HOME }

    const isLoaded = ref(false)

    const {
      title
    } = useVideoConsultations()

    useMeta({
      title
    })

    return {
      prevPage,
      isLoaded,
      title,
      ...useVideoconsultationsBanners()
    }
  }
})
