import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "asVideoConsultationsCards" }
const _hoisted_2 = {
  key: 0,
  class: "asVideoConsultationsCards_wrapper"
}
const _hoisted_3 = { class: "asVideoConsultationsCards_title" }
const _hoisted_4 = {
  key: 1,
  class: "asVideoConsultationsCards_empty"
}
const _hoisted_5 = { class: "asVideoConsultationsCards_banners" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_video_consultations_card = _resolveComponent("as-video-consultations-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.videoConsultations.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoConsultations, (item, key) => {
            return (_openBlock(), _createElementBlock("div", { key: key }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(item.title), 1),
              (_ctx.type !== 'history')
                ? (_openBlock(), _createBlock(_component_as_video_consultations_card, {
                    key: 0,
                    videoConsultation: item
                  }, null, 8, ["videoConsultation"]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.purchased, (consultation, pKey) => {
                return (_openBlock(), _createBlock(_component_as_video_consultations_card, {
                  key: pKey,
                  videoConsultation: consultation,
                  type: "other"
                }, null, 8, ["videoConsultation"]))
              }), 128))
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "empty")
        ])),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "banners")
    ])
  ]))
}