
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: null
    },
    tabs: {
      type: Object,
      default: () => ({} as Dictionary<{
        name: string;
        note?: string;
      }>)
    },
    current: {
      type: String,
      default: ''
    },
    grow: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select'],
  setup (props, ctx) {
    function selectTab (id) {
      ctx.emit('select', id)
    }
    function count (count) {
      return count ? `(${count})` : ''
    }

    return {
      selectTab,
      count
    }
  }
})
