
import { defineComponent, computed } from 'vue'

import useVideoConsultations from '@web/composition/useVideoConsultations'
import { getUrlWithAuthParams } from '@web/common/Utils'
import { IS_MOBILE } from '@web/common/config'

export default defineComponent({
  props: {
    videoConsultation: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'buy'
    }
  },
  setup (props) {
    const {
      isCreatedOrCanceled,
      isPlanned,
      isApproved,
      isDoneOrRefund,
      getDate,
      getTime
    } = useVideoConsultations()

    const link = computed(() => {
      if (props.type === 'buy') {
        return getUrlWithAuthParams(props.videoConsultation.onlineConsultationLandingUrl)
      }
      return {
        name: 'videoconsultation',
        params: {
          id: props.videoConsultation.id
        }
      }
    })

    function openLink (url) {
      if (IS_MOBILE) {
        window.open(url, '_system')
      }
    }

    return {
      link,
      isCreatedOrCanceled,
      isPlanned,
      isApproved,
      isDoneOrRefund,
      getDate,
      getTime,
      openLink
    }
  }
})
